import React from 'react';
import { graphql } from 'gatsby';

import withMeta from '../hocs/withMeta';
import { PageElements } from '../elements';
import {
  IPageDetails,
  IPageContext,
  ITourCategories,
  IFrontPageSearchBox,
} from '../interfaces';
import { Hero } from '../components';

interface IProps {
  data: {
    contentfulPage: IPageDetails;
    allContentfulTourCategory: ITourCategories;
    contentfulTranslationsVarious: {
      mobileFrontPageButtons: {
        version1: string;
        version2: string;
      };
    };
  };
  pageContext: IPageContext;
}

const Home: React.FunctionComponent<IProps> = (props) => {
  const { data, pageContext } = props;
  const {
    title,
    displayTitle,
    subtitle,
    heroImage,
    pageElements,
    banner,
    bannerImages,
    carousel,
    frontPageSearchBoxWhenInput,
    frontPageSearchBoxTypeOfTourInputBox,
    frontPageSearchBoxCalendarClear,
    frontPageSearchBoxSearchButton,
  } = data.contentfulPage;
  const searchBox = {
    frontPageSearchBoxWhenInput,
    frontPageSearchBoxTypeOfTourInputBox,
    frontPageSearchBoxCalendarClear,
    frontPageSearchBoxSearchButton,
  } as IFrontPageSearchBox;
  const categories = data.allContentfulTourCategory.edges.map((c) => c.node);
  return (
    <React.Fragment>
      <Hero
        title={displayTitle || title}
        subtitle={subtitle}
        image={heroImage}
        pageContext={pageContext}
        categories={categories}
        banner={banner}
        bannerImages={bannerImages}
        searchBox={searchBox}
        mobileButtons={
          data.contentfulTranslationsVarious.mobileFrontPageButtons
        }
        isFrontPage
      />
      <PageElements
        elements={pageElements}
        pageContext={pageContext}
        carousel={carousel}
      />
    </React.Fragment>
  );
};
// TODO when home translations are completed
export const query = graphql`
  query HomeQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
    allContentfulTourCategory(
      sort: { fields: title }
      filter: { node_locale: { eq: $locale } }
    ) {
      ...TourCategories
    }
    contentfulTranslationsVarious(node_locale: { eq: $locale }) {
      mobileFrontPageButtons {
        version1
        version2
      }
    }
  }
`;

export default withMeta(Home);
